.message-error {
  display: block;
  text-align: right;
  margin-right: 8%;
  font-size: 100%;
  font-weight: bold;
}

.drop_csv {
  height: 200px;
  -webkit-box-shadow: inset 10px -23px 300px -42px #FF8084;
  -moz-box-shadow: inset 10px -23px 300px -42px #FF8084;
  box-shadow: inset 10px -23px 300px -42px #FF8084;
  border-radius: 16px;
  border: 1px solid #FF8084;
  padding: 15px;

  .drop_csv_enter {
    background: white;
    height: 170px;
    border-radius: 16px;
    border: 1px solid #FF8084;
    padding: 15px;

    &.drop_csv_enter_dark {
      background-color: rgba(25, 25, 25, 0.8);
      height: 40px;
      padding: 6px;
    }

    .drop_csv_border {
      border: 2px dashed #FF8084;
      border-radius: 16px;
      height: 140px;
      text-align: center;
      padding: 10px;
    }
    .drop_csv_border_dark {
      height: 28px;
      border-radius: 8px;
      padding: 0;
    }

    span {
      display: block;
      color: #FF8084;
      text-align: center;
      font-size: 40px;
    }

    strong {
      display: block;
      text-align: center;
      color: #FF8084;
      font-size: 15px;
    }

    button {
      border: 0;
      background: #FF8084;
      color: white;
      font-weight: bold;
      font-size: 15px;

      span {
        display: inline;
        color: white;
        font-size: 12px;
        margin-right: 20px;
      }
    }
  }
}

.drop_csv_file {
  width: 80%;
  height: 46px;
  background-color: rgba(25, 25, 25, 0.8);
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin-top: 6px;
  border: 1px solid #222;
  border-radius: 8px;

  .csv_file_icon {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
